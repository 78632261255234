<template>
  <div class="table_sty">
    <div class="froms" style="margin: 5px">
      <div>基本信息</div>
      <div class="wire"></div>

      <el-row :gutter="20">
        <el-col :span="24">
          <div class="message">
            <p>活动名称：</p>
            <p class="p1">{{ data.name }}</p>

            <div class="sign">
              <div class="sign-1" :style="{ background: data.back_color }">
                {{ data.level_type }}
              </div>
              <div
                class="sign-2"
                :style="{
                  border: `1px  solid ${data.back_color}`,
                  color: data.back_color,
                }"
              >
                {{ data.level_color }}
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="message">
            <p>活动人数：</p>
            <p class="p1">{{ data.people_num }}</p>
          </div>
        </el-col>
       
        <el-col :span="8">
          <div class="message">
            <p>联系人：</p>
            <p class="p1">{{ data.contact }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>联系人电话：</p>
            <p class="p1">{{ data.contact_phone }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>地址：</p>
            <p class="p1">{{ data.place}}</p>
          </div>
        </el-col>
       
        <el-col :span="8">
          <div class="message">
            <p>活动时间：</p>
            <p class="p1">{{ `${data.begin_time}-${data.end_time}` }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>审批状态：</p>
            <el-tag v-if="data.status==0">未审批</el-tag>
            <el-tag v-if="data.status==1">审批中</el-tag>
            <el-tag v-if="data.status==2">审批完成</el-tag>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>是否有宣传资料：</p>
            <el-tag>{{data.has_materials}}</el-tag>
          </div>
        </el-col>
        <el-col :span="8" v-if="data.has_materials=='是'">
          <div class="message">
            <p>宣传资料内容：</p>
            <el-tag>{{data.materials_content}}</el-tag>
          </div>
        </el-col>

        <el-col :span="24">
          <div class="message">
            <p>活动描述：</p>
            <p class="p1">{{ data.desc }}</p>
          </div>
        </el-col>
        
      </el-row>
    </div>

    <div class="block">
      <!-- <div class="title">
        <p>标题：{{ info.name }}</p>
        <p>发起时间：{{ info.add_time }}</p>
        <p>状态：<el-tag v-if="info.status==1">完成</el-tag><el-tag v-else>未完成</el-tag></p>
        <p v-if="info.status==1">完成时间：{{ info.completed_time }}</p>
      </div> -->
      <el-timeline> 
        <el-timeline-item
          v-for="(item, index) in operation_records"
          :key="index"
          :timestamp="item.date"
          placement="top"
        style="padding-bottom: none;">
          <el-card>
            <p>{{ item.nickname }} <el-tag v-if="item.result">({{item.result}})</el-tag></p>
            <h4>{{item.type_name}} : {{ item.remark }}</h4>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>


    <!-- <div class="froms particulars-pop">
      <tablepop :id="id" url_type="activity" ref="tablepop" />
    </div> -->
  </div>
</template>

<script>
import { activitydetail,activityControlDetail } from "../../assets/request/api";
// import tablepop from "../../components/table_pop";
// import tablepop from "../../components/controlog/activity_logs";

export default {
  name: "system_particulars",
  components: {},
  data() {
    return {
      id: "",
      data: {},
      info:{},
      operation_records:[]
    };
  },
  created() {
    let { id } = this.$route.query;
    this.id = id;
    activitydetail({ id }).then((res) => {
      this.data = res.data.data;
    });
    activityControlDetail({ source_id: id }).then((res) => {
        let info = res.data;
        this.info=info;
        this.operation_records = info.operation_records;
      });

  },
  methods: {},
};
</script>
<style lang="less" scoped>
.message {
  display: flex;
  font-size: 15px;
  color: #28307c;
  margin-top: 10px;
  .p1 {
    color: #465269;
  }
}
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.result {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #19be6b;
  }
}
.wire {
  height: 1px;
  background: #e8eaec;
  margin: 10px -15px 0 -15px;
}
.sign {
  margin-left: 30px;
  display: flex;
  div {
    width: 30px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
    box-sizing: border-box
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}
.sign-2 {
  border-radius: 0 5px 5px 0;
}
</style>
